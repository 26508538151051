import React from 'react'

import SEO from '../components/seo'
import Button from '../components/button'
import image from '../images/404.gif'

const NotFoundPage = () => (
  <>
    <SEO title='404: Not found' />
    <div className='text-center max-w-lg my-20 mx-auto header-offset'>
      <h1 className='mb-4'>Ops. Denne siden finnes ikke.</h1>

      <img src={image} alt='' className='mx-auto mb-8' />

      <p>Gå tilbake til forsiden og se om du finner det du trenger der.</p>

      <Button href='/'>Ta meg til forsiden</Button>
    </div>
  </>
)

export default NotFoundPage
